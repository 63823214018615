import axios from "axios";
import {apiConfig} from "@/config";

export default class UnlockMoreService {
    static async claimChest(msisdn) {
        return await axios.post(`${apiConfig.unlockMoreService}/v1/claim-chest`, {
                msisdn: msisdn,
                productId: 5,
            },
        )
    }

    static async headerEnrichmentLogin(encryptedMsisdn) {
        return await axios.get(`${apiConfig.unlockMoreService}/v1/he-login/?encryptedMsisdn=${encryptedMsisdn}`, {
                encryptedMsisdn,
            },
        )
    }

    static async getUser(msisdn) {
        return await axios.post(`${apiConfig.unlockMoreService}/v1/get-user`, {
                msisdn: msisdn,
                productId: 5,
            },
        )
    }

    static async requestOtp(msisdn) {
        return await axios.post(`${apiConfig.unlockMoreService}/v1/request-otp`, {
                msisdn: msisdn,
                productId: 5,
            },
        )
    }

    static async validateOtp(msisdn, otp) {
        return await axios.post(`${apiConfig.unlockMoreService}/v1/validate-otp`, {
                msisdn: msisdn,
                otp,
                productId: 5,
            },
        )
    }

    static async trackUserLogin(msisdn) {
        return await axios.post(`${apiConfig.depService}/track-user-login`, {
                msisdn: msisdn,
                productId: 5,
                serviceId: null,
                isWeb: 1
            },
        )
    }

    static async getUserWinnings(msisdn) {
        const response = await axios.post(`${apiConfig.unlockMoreService}/v1/get-user-winnings`, {
                msisdn,
                productId: 5,
            },
        )
        return response.data
    }

    static getUserChest(numberOfChests) {
        const chestsArray = []
        for (let i = 0; i < numberOfChests; i++) {
            chestsArray.push({hasClaimed: false, prize: null},)
        }
        return chestsArray
    }

    static async getUerBannerPriority(msisdn, category) {
        const response = await axios.post(`${apiConfig.depService}/upsell/get-upsell-services`, {
            msisdn,
            productId: 5,
            category
        })
        return response.data;
    }

    static async doiOne(msisdn, serviceId) {
        const response = await axios.post(`${apiConfig.depService}/upsell/doi-one`, {
            msisdn,
            productId: 5,
            serviceId,
            externalRef: 1234,
            channel: "WAP",
            doiChannel: "WAP_REDIRECT"
        })
        return response.data;
    }
}

import { createRouter, createWebHistory } from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";

const routes = [
  {
    path: '/',
  },
  {
    path: '/login',
    name: 'LoginView',
    redirect: '/login/request-otp',
    component: () => import('../views/LoginView.vue'),
    children: [
      {
        path: 'request-otp',
        component: () => import('../views/loginViews/RequestOtp.vue')
      },
      {
        path: 'submit-otp',
        component: () => import('../views/loginViews/SubmitOtp.vue')
      },
    ]
  },
  {
    path: '/game',
    redirect: '/game/game-choice',
    name: 'GameView',
    component: () => import('../views/GameView.vue'),
    children: [
      {
        path: 'game-choice',
        component: () => import('../views/gameViews/GameChoice.vue')
      },
      {
        path: 'flying-kite',
        component: () => import('../views/gameViews/FlyingKiteView.vue')
      },
      {
        path: 'chests',
        component: () => import('../views/gameViews/ChestsView.vue')
      },
    ]
  },
  {
    path: '/menu',
    name: 'MenuView',
    component: () => import(/* webpackChunkName: "about" */ '../views/MenuView.vue')
  },
  {
    path: '/user-winnings',
    name: 'UserWinnings',
    component: () => import(/* webpackChunkName: "about" */ '../views/menuViews/UserWinnings.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "about" */ '../views/menuViews/TermsAndConditions.vue')
  },
  {
    path: '/more-plays',
    name: 'UpsellView',
    component: () => import(/* webpackChunkName: "about" */ '../views/UpsellView.vue')
  },
  {
    path: '/subscribe/:selectedCategory/:serviceName/:subId/:confirmOrPlay',
    name: 'SubscriptionView',
    params: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/SubscriptionView.vue')
  },
  {
    path: '/how-it-works',
    name: 'HowItWorksView',
    component: () => import(/* webpackChunkName: "about" */ '../views/menuViews/HowItWorksView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
})

router.beforeEach(async (to, from, next) => {
  await userAuthenticate(to, from, next);
})


export default router

import UserSessionFunctions from "@/functions/userSessionFunctions";
import UnlockMoreService from "@/services/unlockMoreService";
import store from "@/store";

export async function userAuthenticate(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMsisdn = urlParams.get('e-msisdn');
    if (to.path === '/') {
        if (encryptedMsisdn) {
            const response = await UnlockMoreService.headerEnrichmentLogin(encryptedMsisdn);
            if (response.data.error) return next('/login/request-otp')
            else {
                await UnlockMoreService.trackUserLogin(response.data);
                const user = await UnlockMoreService.getUser(response.data);
                UserSessionFunctions.setUserSesssionHasLoggedIn(true);
                UserSessionFunctions.setUserSesssionMsisdn(user.data.msisdn);
                store.commit('setUser', user.data);
                return next('/game')
            }
        } else return next('/login/request-otp')
    }
    if (to.path === '/login/request-otp') return next();
    if (to.path === '/login/submit-otp') return next();
    if (to.path === '/terms-and-conditions') return next()
    if (UserSessionFunctions.getUserSesssionHasLoggedIn()) {
        const user = await UnlockMoreService.getUser(UserSessionFunctions.getUserSesssionMsisdn());
        store.commit('setUser', user.data);
        return next()
    } else return next('/login/request-otp')

}
